import './App.css'
import logo from './assets/images/dix-branca.svg'
import iconAddress from './assets/images/icon-address.svg'
import iconInstagram from './assets/images/icon-instagram.svg'
import iconPhone from './assets/images/icon-phone.svg'
import iconWhatsapp from './assets/images/icon-whatsapp.svg'

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="logo__container">
          <img src={logo} alt="dix" />
        </div>
        <div className="details__container">
          <div className="details__row">
            <div className="details__icon">
              <img src={iconPhone} alt="phone" />
            </div>
            <div className="details__value">(21) 3729-2160</div>
          </div>

          <div className="details__row">
            <div className="details__icon">
              <img src={iconWhatsapp} alt="whatsapp" />
            </div>
            <div className="details__value">(21) 97646-9100</div>
          </div>

          <div className="details__row">
            <div className="details__icon">
              <img src={iconInstagram} alt="instagram" />
            </div>
            <a
              className="details__value"
              href="https://www.instagram.com/dix_radiologiaoraldigital/"
              target="_blank"
              rel="noreferrer"
            >
              @dix_radiologiaoraldigital
            </a>
          </div>

          <div className="details__row details__row--icon-top">
            <div className="details__icon">
              <img src={iconAddress} alt="address" />
            </div>
            <div className="details__value">
              Rua Dr Pereira Dos Santos, 107, Ed. Monet 10º andar, salas 1020,
              1021 e 1022 Centro, Itaboraí (Em frente ao Subway)
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
